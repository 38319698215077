import BumbleLogo from '../assets/logos/bumble.svg';
import DailyPayLogo from '../assets/logos/daily-pay.svg';
import PinterestLogo from '../assets/logos/pinterest.svg';
import UpworkLogo from '../assets/logos/upwork.svg';

const CarouselCardColor = {
  BLUE: 'blue',
  GREEN: 'green',
  PURPLE: 'purple',
  RED: 'red',
  YELLOW: 'yellow',
};

export const Content = {
  hero: {
    image: '/images/unitq-sources.png',
    subtitle: `Know what your users like, dislike,
    and want more of so you can take action on what's working and what's not with genAI`,
    title: {
      text: 'Get critical insights fast from **user** feedback',
      config: [
        'user',
        'customer',
        'community',
        'member',
        'shopper',
        'viewer',
        'listener',
        'player',
        'guest',
        'subscriber',
      ],
    },
    videoUrl: 'https://vimeo.com/868284698',
  },
  quoteConfig: {
    quotes: [
      {
        author: {
          avatar: '/images/v2/customer-avatars/todd-upwork.png',
          name: 'Todd Ranson',
          role: 'Sr. Director, \n Customer 360°',
        },
        color: CarouselCardColor.GREEN,
        logo: UpworkLogo,
        text: `We've been leaning into unitQ to understand what our customers are saying at scale,
        separate out signals from noise and expedite getting insights into the hands of the key decision makers.`,
      },
      {
        author: {
          avatar: '/images/v2/customer-avatars/dar-daily-pay.png',
          name: 'Dar Miranda',
          role: 'VP Product Management \n UX Design & Research',
        },
        color: CarouselCardColor.YELLOW,
        logo: DailyPayLogo,
        text: `unitQ captures user feedback data from so many sources,
        but then also automates the categorizations, evaluates sentiment,
        and gives us real product quality insights without any extra effort.`,
      },
      {
        author: {
          avatar: '/images/v2/customer-avatars/vani-pinterest.png',
          name: 'Vani Kumar',
          role: 'Head of Quality',
        },
        color: CarouselCardColor.RED,
        logo: PinterestLogo,
        text: `Pinterest is in the business of inspiring people and you cannot inspire people
        if they're having a less than ideal experience on your app. Seeing both ticket data and publicly available user feedback
        flow into one tool and being able to slice and dice by app version,
        country, language, platform, etc  in real time is gold for us.`,
      },
      {
        author: {
          avatar: '/images/v2/customer-avatars/robyn-bumble.png',
          name: 'Robyn Andrews',
          role: 'Community Insights Manager',
        },
        color: CarouselCardColor.PURPLE,
        logo: BumbleLogo,
        text: `We've been able to spot new bugs, track sentiment of new product releases,
        monitor our app store performance and measure the impact of each quality monitor on specific KPIs.`,
      },
    ],
  },
  seo: {
    appendHostUrl: true,
    description: 'unitQ measures the quality of your products, services, ' +
      'and experiences in real time so you can take action on ' +
      'what\'s working and what\'s not with AI insights',
    src: '/images/v2/seo/home.jpg',
    title: 'Get critical insights fast from user feedback',
  },
  solutions: {
    title: 'Scale the reach of your teams with **actionable user insights**',
  },
  statsConfig: {
    stats: [
      [
        { description: 'Decrease in\n user churn', value: '50%' },
        { description: 'Reduction in\n support tickets', value: '40%' },
        { description: 'Faster issue\n resolution time', value: '2x' },
      ],
      [
        { description: 'Reduced\n support cost', value: '30%' },
        { description: 'Increase in\n user retention', value: '100%' },
        { description: 'Boost in NPS', value: '18%' },
      ],
    ],
    title: 'Boost **visibility** into user detected issues to drive growth',
  },
};
