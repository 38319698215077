// extracted by mini-css-extract-plugin
export var awsPage = "aws-page-module--aws-page--fe31c";
export var awsSectionRow = "aws-page-module--aws-section-row--09e0f";
export var awsSectionRowLogo = "aws-page-module--aws-section-row-logo--dbb64";
export var chevronAct = "aws-page-module--chevron--act--ca8c1";
export var chevronAggregate = "aws-page-module--chevron--aggregate--ae771";
export var chevronCategorize = "aws-page-module--chevron--categorize--e31de";
export var chevronClean = "aws-page-module--chevron--clean--0b655";
export var customersCarouselSectionCol = "aws-page-module--customers-carousel-section-col--3e7f8";
export var customersCarouselSectionRow = "aws-page-module--customers-carousel-section-row--98caf";
export var hero = "aws-page-module--hero--53e0a";
export var heroContainer = "aws-page-module--hero-container--37f49";
export var techPartnersRow = "aws-page-module--tech-partners-row--8048b";
export var workflowContainer = "aws-page-module--workflow-container--d87d8";