import React, { useMemo } from 'react';
import { Col, Row } from 'react-bootstrap';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { UqLegacyRequestDemoButton } from '../../components/v2';

import * as styles from './integrations-header.module.scss';

IntegrationsHeaderComponent.propTypes = {
  logoName: PropTypes.string,
  title: PropTypes.string,
  withCallToAction: PropTypes.bool,
};

function IntegrationsHeaderComponent({ logoName, title, withCallToAction }) {
  const logoSrc = useMemo(() => `/images/integrations/${logoName}.svg`, [logoName]);

  return (
    <Row noGutters className={classNames(styles.row, 'justify-content-center')}>
      <Col className={classNames(styles.col, 'justify-content-center')}>
        <header className={styles.container}>
          <div className={styles.contentWrapper}>
            <img
              className={styles.mainLogo}
              alt={title}
              height="176"
              src={logoSrc}
            />
            <span className={styles.title}>{title}</span>
          </div>
          {!!withCallToAction && (
            <div className={styles.mainCallToAction}>
              <UqLegacyRequestDemoButton className="UQ-button-revamp large primary">
                Request Demo
              </UqLegacyRequestDemoButton>
            </div>
          )}
        </header>
      </Col>
    </Row>
  );
}

export const IntegrationsHeader = React.memo(IntegrationsHeaderComponent);
