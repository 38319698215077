import * as styles from './aws-page.module.scss';

export const FEEDBACK_TRANSFORMING_STEPS = [
  {
    classes: { chevron: styles.chevronAggregate },
    label: 'Aggregate',
    description: `Consolidate feedback across support tickets, chats, social media, 
    app reviews, and surveys into a central, searchable repository.`,
  },
  {
    classes: { chevron: styles.chevronClean },
    label: 'Clean',
    description: `Translate text from 70+ languages, normalize and remove PII, 
    enrich with sentiment analysis, and append with product metadata.`,
  },
  {
    classes: { chevron: styles.chevronCategorize },
    label: 'Categorize',
    description: `No need to rely on tagging. Our machine learning models classify 
    feedback into hundreds of precise categories, better than a human ever could.`,
  },
  {
    classes: { chevron: styles.chevronAct },
    label: 'Act',
    description: `Once categorized and quantified, feedback is actionable. 
    Our platform helps you monitor product quality and synthesize user insights.`,
  },
];
