import { useCallback, useEffect } from 'react';

export function useAnimationListeners(handleOutEvent = false) {
  const animationInHandler = useCallback((event) => {
    const target = event.detail.target;
    const newUrl = target?.getAttribute('data-animation-url');

    if (newUrl && target.style) {
      target.style.backgroundImage = `url(${newUrl})`;
    }
  }, []);

  const animationOutHandler = useCallback((event) => {
    const target = event.detail.target;
    const newUrl = target?.getAttribute('data-still-url');

    if (newUrl && target.style) {
      target.style.backgroundImage = `url(${newUrl})`;
    }
  }, []);

  useEffect(() => {
    document.addEventListener('sal:in', animationInHandler);

    if (handleOutEvent) {
      document.addEventListener('sal:out', animationOutHandler);
    }

    return () => {
      document.removeEventListener('sal:in', animationInHandler);

      if (handleOutEvent) {
        document.removeEventListener('sal:out', animationOutHandler);
      }
    }
  }, [animationInHandler, animationOutHandler, handleOutEvent]);
}
