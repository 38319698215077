import React from 'react';

import { UqLayoutV2, UqAppBarV2, UqNavbarV2, UqFooterV2 } from '@uq-components';
import { AwsPage } from '../containers/aws';

import * as styles from './aws.module.scss';

export default function UqAwsPage() {
  return (
    <UqLayoutV2 theme="light">
      <UqAppBarV2
        className={styles.appbar}
        static
      >
        <UqNavbarV2 />
      </UqAppBarV2>

      <AwsPage />

      <UqFooterV2 />
    </UqLayoutV2>
  );
}
