import classNames from 'classnames';
import React, { useMemo } from 'react';
import { Col } from 'react-bootstrap';

import { Content } from '../../content/v2/home';
import { UQAnchor, UQFigureRow, UQPageContentRow, UqQuoteCarousel, UQWorkflow } from '../../components';
import { IntegrationsHeader } from '../../components/integrations-header';
import SEO from '../../components/seo';
import { useAnimationListeners } from '../../hooks';
import { FEEDBACK_TRANSFORMING_STEPS } from './feedback-transforming-steps';

import * as styles from './aws-page.module.scss';

export const AwsPage = () => {
  useAnimationListeners();
  const renderWorkflowDescription = useMemo(() => (
    <>
      You hear from customers all the time, but making sense of feedback spread across siloed
      sources is nearly impossible. unitQ makes it easy to search, monitor, and analyze all user
      feedback from one place to provide a 360-degree view of your customer and ensure you are
      delivering the best digital experience possible based on what they are telling you.
      <br /><br />
      We turn qualitative feedback into quantitative data you can use to increase engagement
      and decrease churn in four steps:
    </>
  ), []);

  return (
    <article className={classNames(styles.awsPage, 'aws-quality-page')}>
      <SEO
        title="AWS and unitQ"
        description={
          `unitQ together with Amazon Web Services empower teams to proactively identify feedback trends,
          send timely alerts, and quickly identify, prioritize, and fix quality issues for their products and services hosted in AWS.`
        }
      />
      <div className={styles.hero}>
        <IntegrationsHeader
          logoName="aws-and-unitq"
          title="AWS and unitQ"
          withCallToAction
        />
      </div>
      <UQPageContentRow classes={{ container: styles.workflowContainer }}>
        <UQWorkflow
          description={renderWorkflowDescription}
          steps={FEEDBACK_TRANSFORMING_STEPS}
          title="unitQ Digital Customer Experience"
        />
      </UQPageContentRow>
      <UQFigureRow
        classes={{ row: styles.techPartnersRow }}
        description="unitQ integrates with more than 50 different data sources, and partners
          with leading technology companies such as Zendesk, Ada, and AWS to empower customers
          to make the most of their customer feedback."
        imageUrl="/images/aws/apps.svg"
        title="Technology Partnerships"
      />
      <UQFigureRow
        description="Send timely alerts to the right teams via Slack, Jira,
          or PagerDuty when new feedback data arrives, when Quality
          Monitors start to trend, or new emerging issues are detected."
        direction="left"
        imageUrl="/images/aws/notifications.svg"
        title="Notify Your Teams with Timely Alerts"
      />
      <UQFigureRow
        description="Quickly detect all critical issues, automatically enriched with the metadata
         and granular insights your teams need to prioritize, troubleshoot, and fix quality issues."
        imageUrl="/images/aws/product-overview-feed.svg"
        title="Fix Product Quality Issues Faster"
      />
      <UQFigureRow
        classes={{ image: styles.awsSectionRowLogo, row: styles.awsSectionRow }}
        description="AWS provides a massive global cloud infrastructure that allows unitQ to quickly innovate, experiment
         and iterate. Companies of all sizes, including unitQ, use AWS to deliver fast and reliable solutions at scale."
        direction="left"
        imageUrl="/images/plogos/aws-logo.svg"
        title="unitQ is built to scale on AWS"
      />
      <UQFigureRow
        classes={{ row: styles.awsSectionRow }}
        description={
          <>
            <span>Over 300k businesses purchase software through the AWS Marketplace today. unitQ is </span>
            <UQAnchor
              external
              href="https://aws.amazon.com/marketplace/pp/prodview-okcaeu6c542ko?sr=0-1&ref_=beagle&applicationId=AWSMPContessa"
              label="available on the AWS Marketplace"
            />
            <span> to simplify the procurement process and to help you </span>
            <UQAnchor
              external
              href="https://aws.amazon.com/marketplace/features/custom-terms-pricing/"
              label="make the most of your spend"
            />
            <span>, drawing down your </span>
            <UQAnchor
              external
              href="https://aws.amazon.com/pricing/enterprise/"
              label="AWS EDP commitment"
            />
            .
          </>
        }
        imageUrl="/images/aws/aws-marketplace-logo.svg"
        title="unitQ is available on the AWS Marketplace"
      />
      <UQPageContentRow
        fullWidth
        classes={{ row: styles.customersCarouselSectionRow }}
      >
        <Col className={styles.customersCarouselSectionCol}>
          <UqQuoteCarousel {...Content.quoteConfig} />
        </Col>
      </UQPageContentRow>
    </article>
  );
}
